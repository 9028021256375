<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="请输入商户名称"
            class="search-input"
            clearable
            v-model="searchObj.merchantName"
          ></el-input>
          <el-select
            placeholder="请选择门店"
            class="search-input ml-15"
            v-model="searchObj.shopId"
            @change="chooseStore"
          >
            <el-option
              v-for="(item, index) in storeList"
              :key="index"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            placeholder="请选择楼层"
            class="search-input ml-15"
            v-model="searchObj.floor"
             @change="pageNum=1"
          >
            <el-option
              v-for="(item, index) in floorList"
              :key="index"
              :label="item.floorName"
              :value="item.floorName"
            ></el-option>
          </el-select>
          <el-select
            placeholder="请选择商户类别"
            class="search-input ml-15"
            v-model="searchObj.merchantClassifyId"
            @change="pageNum=1"
          >
            <el-option
              v-for="(item, index) in merchantClasses"
              :key="index"
              :label="item.classifyName"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <router-link
                :to="{ name: 'Merchants_new', params: { addFlag: true } }"
              >
                <el-button class="ml-15" type="primary">添加商户</el-button>
              </router-link>
              <el-button
                class="ml-15"
                type="primary"
                @click="disableMerchant"
                :disabled="selectedRows.length < 1"
                >批量下架</el-button
              >
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column
              label="商户名称"
              prop="merchantName"
            ></el-table-column>
            <el-table-column label="店铺图片" prop="imgs">
              <template slot-scope="scope">
                <el-avatar
                  :src="picsPath(scope.row.imgs)"
                  shape="square"
                  alt="加载失败"
                  :key="Math.random()"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column label="所属门店" prop="shopName"></el-table-column>
            <el-table-column label="楼层" prop="floor"></el-table-column>
            <el-table-column label="门店号" prop="door"></el-table-column>
            <el-table-column label="粉丝">
              <template slot-scope="scope">
                <div @click="checkFans(scope.row.merchantId)" class="fans">
                  {{ scope.row.concernCount }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="联系方式"
              prop="merchantPhone"
            ></el-table-column>
            <el-table-column
              label="类别"
              prop="merchantClassifyName"
            ></el-table-column>
            <el-table-column label="排名" prop="rankSort"></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="上架"
                  inactive-text="下架"
                  @change="upDownShelf($event, scope.row.merchantId)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <router-link
                    :to="{
                      name: 'Merchants_new',
                      params: {
                        merchantId: scope.row.merchantId,
                        addFlag: false,
                      },
                    }"
                  >
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-edit-outline"></i>
                      编辑
                    </el-link>
                  </router-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            :type="'list'"
            @changePage="changePage"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="showFans">
      <div class="pad-container">
        <el-table class="mt-24 member-table" :data="fansData">
          <el-table-column label="用户昵称" prop="nickname"></el-table-column>
          <el-table-column label="手机号码" prop="phone"></el-table-column>
          <el-table-column
            label="关注时间"
            prop="concernTime"
          ></el-table-column>
        </el-table>
        <Pagination
          :total="fansTotal"
          :pageNum="fansPageNum"
          :pageSize="fansPageSize"
          :pageSizes="fansPageSizes"
          :hideSizes="true"
          :type="'dialog'"
          @changePage="changePage"
          @syncPageData="syncPageData"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import request from "@/utils/request";
import Pagination from "@/components/Pagination/index";
export default {
  name: "members_list",
  components: {
    Pagination,
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        shopId: "",
        merchantName: "",
        floor: "",
        merchantClassifyId: "",
      },
      storeList: [],
      merchantClasses: [],
      floorList: [],
      // 会员表格数据内容
      tableData: [],
      showFans: false,
      merchantId: "",
      fansData: [],
      selectedRows: [],
      addFlag: true,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      fansTotal: 0,
      fansPageNum: 1,
      fansPageSize: 5,
      fansPageSizes: [5, 8, 10, 15],
    };
  },
  created() {
    this.getStoreList();
    // this.getMerchantsList()
    this.getMerchantClasses();
    this.initData();
  },
  methods: {
    //获取门店列表
    getStoreList() {
      request({
        url: "/goods-service/store/listOfPage",
        method: "get",
      }).then((res) => {
        // // console.log('shopList', res.data)
        this.storeList = res && res.data.body.list;
      });
    },
    //获取商户列表
    getMerchantsList() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      request({
        url: "/customer-service/lrMerchant/queryMerchantByShop",
        method: "post",
        data,
      }).then((res) => {
        if (res.data.body && res.data.body.list) {
          this.merchantList = res.data.body.list;
        }
      });
    },
    //获取商户分类
    getMerchantClasses() {
      request({
        url: "/customer-service/lrMerchant/classifyAll",
        method: "get",
      }).then((res) => {
        if (res.data.code == 200) {
          this.merchantClasses = res.data.body;
        }
      });
    },
    //选择门店获取楼层信息
    chooseStore(e) {
      this.getFloorList(e);
      this.pageNum =  1,
      this.floorList = [];
      this.searchObj.floor = "";
    },
    //获取门店楼层
    getFloorList(id) {
      request({
        url: "/goods-service/store/listOfPage4?id=" + id,
        method: "post",
      }).then((res) => {
        this.floorList = res.data.body;
      });
    },
    initData(searchData = {}) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      data = Object.assign(data, searchData);
      request({
        url: "/customer-service/lrMerchant/queryMerchant",
        method: "post",
        data,
      }).then((res) => {
        this.total = res.data.body.total;
        this.tableData = res.data.body.list;
      });
    },
    // 搜索事件
    search(type) {
      this.initData(this.searchObj);
    },
    reset() {
      this.searchObj = {
        shopId: "",
        merchantName: "",
        floor: "",
        merchantClassifyId: "",
      };
      this.initData();
    },
    // 会员多选事件
    tableSelect(e) {
      this.multiSeletction = e;
    },
    //表格行数据选中
    handleSelectionChange(arr) {
      this.selectedRows = arr;
    },
    //查询
    queryMerchant() {
      let data = Object.assign(this.searchObj, {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      });
      request({
        url: "/customer-service/lrMerchant/queryMerchant",
        method: "post",
        data,
      }).then((res) => {
        this.total = res.data.body.total;
        this.tableData = res.data.body.list;
      });
    },
    //批量下架
    disableMerchant() {
      let idArr = [];
      this.selectedRows.forEach((item) => {
        idArr.push(item.merchantId);
      });
      request({
        url: "/customer-service/lrMerchant/disabledMerchant",
        method: "post",
        data: idArr,
      }).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.initData();
      });
    },
    upDownShelf(e, id) {
      request({
        url: `/customer-service/lrMerchant/operationMerchant?merchantId=${id}&status=${e}`,
        method: "post",
      }).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    //查看粉丝
    checkFans(id) {
      this.merchantId = id ? id : this.merchantId;
      let data = {
        merchantId: this.merchantId,
        pageSize: this.fansPageSize,
        pageNum: this.fansPageNum,
      };
      request({
        url: "/customer-service/lrMerchant/queryConcernInfo",
        method: "post",
        data,
      }).then((res) => {
        this.fansTotal = res.data.body.total;
        this.fansData = res.data.body.list;
      });
      this.showFans = true;
    },
    changePage(type) {
      if (type == "list") {
        console.log(this.searchObj);
        this.initData(this.searchObj);
      }
      if (type == "dialog") this.checkFans();
    },
    syncPageData(data, type) {
      if (type == "list") {
        this.pageNum = data.num;
        this.pageSize = data.size;
      }
      if (type == "dialog") {
        this.fansPageNum = data.num;
        this.fansPageSize = data.size;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.fans {
  color: #409eff;
  cursor: pointer;
}
</style>
